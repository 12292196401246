<template>
  <component :is="as || 'li'" class="flex w-full items-center gap-2.5 text-sm">
    <UiIcon name="search" class="size-4 shrink-0" aria-hidden="true" />
    <nuxt-link
      :to="productLink"
      class="flex-auto py-5 font-light leading-[16px]"
      v-html="titleWithHighlight"
    />
  </component>
</template>

<script setup lang="ts">
import { CardProduct } from "~/composables";
import { CatalogData } from "~/types/api/catalog.types";
import { SearchProduct } from "~/types/api/search.types";

const props = defineProps<{
  as?: string;
  product: SearchProduct | CardProduct | CatalogData;
  search: string;
}>();

const { productLink } = useProduct(props.product as SearchProduct);

const titleWithHighlight = computed(() => {
  const safeHighlight = props.search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const re = new RegExp(safeHighlight, "gi");
  const highlightedText = props.product.title.replace(
    re,
    "<span class='font-normal'>$&</span>",
  );

  return highlightedText;
});
</script>
